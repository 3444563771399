const slideInFromTop = {
    enter: {
        y: -30,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            delayChildren: 0,
            staggerChildren: 0.1,
        },
    },
    exit: {
        y: -30,
        opacity: 0,
    },
};
const slideAnimation = {
    entering: ({ delta }) => ({
        x: delta < 0 ? '-100%' : '100%',
        opacity: 0,
    }),
    still: {
        x: 0,
        opacity: 1,
    },
    exiting: ({ delta }) => ({
        x: delta < 0 ? '100%' : '-100%',
        opacity: 0,
    }),
};
const slideAnimationTransition = {
    duration: 0.15,
    type: 'spring',
    stiffness: 300,
    damping: 25,
};
const slideInDown = {
    initial: 'enter',
    animate: 'visible',
    exit: 'exit',
    variants: slideInFromTop,
    transition: {
        duration: 0.1,
    },
};
const fadeIn = (controls, key) => ({
    custom: key,
    initial: { opacity: 0 },
    animate: controls,
});
const slideInFromRight = {
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    transition: { duration: 0.15, ease: 'easeInOut' },
    variants: {
        hidden: { x: '100px', opacity: 0 },
        visible: { x: '0', opacity: 1 },
    },
};
export const animations = {
    slideInDown,
    fadeIn,
    slideAnimation,
    slideAnimationTransition,
    slideInFromRight,
};
