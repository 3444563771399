import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { ResponseStatus } from '@budbee/js/dist/tracking-api';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import { envConfig } from '../../utils/env.config';
import { BudbeeBaseButton } from '../base-button/budbee';
import { AnimatedView } from '../shared/animated-view';
import { AlertCircleIcon } from '../shared/icons/alert-circle';
import { BudbeeLogoIcon } from '../shared/icons/budbee-logo-icon';
import { TextInput } from '../shared/text-input';
import { View } from '../shared/view';
import { styles } from './styles';
export var MutateState;
(function (MutateState) {
    MutateState["IDLE"] = "IDLE";
    MutateState["ERROR"] = "ERROR";
    MutateState["SUCCESS"] = "SUCCESS";
    MutateState["LOADING"] = "LOADING";
})(MutateState || (MutateState = {}));
const isEmail = (email) => email.includes('@');
export function SearchParcel() {
    const trackingApi = useTrackingApi();
    const [mutateState, setMutateState] = useState(MutateState.IDLE);
    const [form, setForm] = useState({ id: '', code: '' });
    const { t } = useI18n();
    const handleChange = (name) => {
        return (value) => {
            setForm((prev) => ({ ...prev, [name]: value }));
        };
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setMutateState(MutateState.LOADING);
        const { code, id } = form;
        const isCodeEmail = isEmail(code);
        const trimmedCode = code.trim();
        const params = {
            trackingId: id.trim(),
            email: isCodeEmail ? trimmedCode : '',
            phoneNumber: isCodeEmail ? '' : trimmedCode,
        };
        try {
            const response = await trackingApi.orderSearch(params).call();
            if (response.status === ResponseStatus.COMPLETED) {
                const { trackingId, authCode } = response.payload;
                const { origin } = new URL(envConfig.TRACKING_API_HOST);
                window.location.href = `${origin}/${trackingId}?auth=${authCode}`;
                setMutateState(MutateState.SUCCESS);
            }
            else {
                setMutateState(MutateState.ERROR);
            }
        }
        catch (e) {
            setMutateState(MutateState.ERROR);
        }
    };
    const isFormValid = useMemo(() => {
        return form.id.length >= 4 && form.code.length >= 4;
    }, [form.id, form.code]);
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: [styles.container, styles.backgroundImage], children: [_jsx(View, { style: styles.imageOverlay }), _jsx("a", { href: "https://budbee.com", className: css(styles.logoLink), children: _jsx(BudbeeLogoIcon, { className: css(styles.logoIcon) }) })] }), _jsx(View, { style: [styles.container, styles.formContainer], children: _jsxs(View, { style: styles.form, children: [_jsx("p", { className: css(styles.title), children: t('search_page.title') }), _jsx("p", { className: css(styles.text), children: t('search_page.paragraph_1') }), _jsx("p", { className: css(styles.text), children: t('search_page.paragraph_2') }), _jsxs("form", { onSubmit: handleSubmit, children: [_jsx("div", { className: css(mutateState === MutateState.ERROR && styles.shake), children: _jsx(TextInput, { id: "id", name: "id", value: form.id, style: styles.input, placeholder: t('search_page.parcel_id'), contentContainerStyle: styles.inputContainer, onChange: handleChange('id') }) }), _jsx("div", { className: css(mutateState === MutateState.ERROR && styles.shake), children: _jsx(TextInput, { id: "code", name: "code", value: form.code, style: styles.input, contentContainerStyle: styles.inputContainer, placeholder: t('search_page.email_or_phone_number'), onChange: handleChange('code') }) }), _jsx(BudbeeBaseButton, { size: "lg", type: "submit", style: styles.button, label: t('search_page.search'), isLoading: mutateState === MutateState.LOADING, disabled: mutateState === MutateState.LOADING || !isFormValid }), _jsx(AnimatedView, { animation: "slideInFromRight", children: mutateState === MutateState.ERROR && (_jsxs("span", { className: css(styles.error), children: [_jsx("div", { className: css(styles.errorIcon), children: _jsx(AlertCircleIcon, {}) }), t('search_page.error_message')] })) }, mutateState)] })] }) })] }));
}
