import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { animations } from '../../../utils/animations';
import { View } from '../view';
export const AnimatedView = (props) => {
    const { animation, contentContainerStyle, ...rest } = props;
    const controls = useAnimation();
    useEffect(() => {
        if (animation !== 'fadeIn') {
            return;
        }
        controls.start((i) => ({
            opacity: 1,
            transition: { delay: i / 20 },
        }));
    }, []);
    const getAnimation = useCallback(() => {
        switch (animation) {
            case 'fadeIn':
                return animations.fadeIn(controls, (props === null || props === void 0 ? void 0 : props.delay) || 0);
            case 'slideInDown':
                return animations.slideInDown;
            case 'slideInFromRight':
                return animations.slideInFromRight;
            default:
                return {};
        }
    }, [animation, controls, props]);
    return (_jsx(AnimatePresence, { children: _jsx(motion.div, { ...getAnimation(), className: contentContainerStyle, children: _jsx(View, { ...rest }) }) }));
};
