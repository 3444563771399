import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { ReactSVG } from 'react-svg';
import { inlineStyles } from '../../../utils/inline-styles';
const replaceElementColor = (element, color) => {
    if (!element || !color)
        return;
    if ((element === null || element === void 0 ? void 0 : element.hasAttribute) && (element === null || element === void 0 ? void 0 : element.hasAttribute('fill'))) {
        if ((element === null || element === void 0 ? void 0 : element.getAttribute('fill')) !== 'none') {
            element.setAttribute('fill', color);
        }
    }
    if ((element === null || element === void 0 ? void 0 : element.hasAttribute) && (element === null || element === void 0 ? void 0 : element.hasAttribute('stroke'))) {
        element.setAttribute('stroke', color);
    }
    element.childNodes.forEach((child) => {
        replaceElementColor(child, color);
    });
};
/**
 * @deprecated Use Icons instead
 */
export const Icon = (props) => {
    const { src, color, opacity, size = 30, disabled = false } = props;
    return (_jsx(ReactSVG, { src: src, className: css(inlineStyles({
            width: size,
            height: size,
            opacity: disabled ? 0.5 : opacity,
            display: 'flex',
            alignItems: 'center',
        })), afterInjection: (_error, svg) => {
            var _a, _b, _c;
            (_a = svg === null || svg === void 0 ? void 0 : svg.parentElement) === null || _a === void 0 ? void 0 : _a.setAttribute('width', `${size}`);
            (_b = svg === null || svg === void 0 ? void 0 : svg.parentElement) === null || _b === void 0 ? void 0 : _b.setAttribute('height', `${size}`);
            (_c = svg === null || svg === void 0 ? void 0 : svg.parentElement) === null || _c === void 0 ? void 0 : _c.setAttribute('style', 'display: flex; align-items: center;');
        }, beforeInjection: (svg) => {
            svg.setAttribute('display', 'flex');
            svg.setAttribute('width', `${size}`);
            svg.setAttribute('height', `${size}`);
            svg.childNodes.forEach((child) => {
                replaceElementColor(child, color);
            });
        } }));
};
