import { Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, Color, FontSize, IconSize, MediaQuery, Spacing } from '../../styles';
import imageList from '../../utils/image-list';
const shake = {
    '0%': { transform: 'translateX(7px)' },
    '50%': { transform: 'translateX(-7px)' },
    '100%': { transform: 'translateX(0px)' },
};
export const styles = StyleSheet.create({
    root: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#E7F0EB',
        position: 'relative',
        [MediaQuery.maxWidthMd]: {
            flexDirection: 'column',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backgroundImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundImage: `url(${imageList.DeliveryMan})`,
        [MediaQuery.maxWidthMd]: {
            backgroundImage: `url(${imageList.DeliveryManMobile})`,
            flex: '0 0 200px',
        },
        [MediaQuery.minWidthMd]: {
            flex: 1,
        },
    },
    formContainer: {
        display: 'flex',
        padding: '48px 24px',
        boxSizing: 'border-box',
        alignItems: 'center',
        flexDirection: 'column',
        [MediaQuery.maxWidthMd]: {
            flex: '0 0 auto',
        },
        [MediaQuery.minWidthMd]: {
            flex: 1,
        },
    },
    form: {
        width: '100%',
        maxWidth: '500px',
    },
    title: {
        ...Typography.Budbee.Web.h1,
        marginTop: 0,
        marginBottom: 48,
        textAlign: 'center',
        fontSize: FontSize['10xl'],
        [MediaQuery.maxWidthMd]: {
            fontSize: FontSize['6xl'],
            color: Color.White,
            position: 'absolute',
            top: 90,
            left: 0,
            right: 0,
        },
    },
    text: {
        ...Typography.Budbee.Web['body-lg'],
        maxWidth: '500px',
        textAlign: 'center',
        fontSize: FontSize.lg,
        marginTop: 0,
        marginBottom: Spacing.md,
        ':last-of-type': {
            marginBottom: Spacing['6xl'],
        },
        [MediaQuery.maxWidthMd]: {
            fontSize: FontSize.md,
        },
    },
    inputContainer: {
        width: '100%',
        marginBottom: Space.md,
    },
    input: {
        backgroundColor: Color.White,
    },
    button: {
        width: '100%',
        marginTop: Spacing.lg,
        borderRadius: BorderRadius['2xl'],
    },
    imageOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0.7,
        background: 'linear-gradient(180deg, #18706D 0%, #2D8F83 100%)',
    },
    error: {
        ...Typography.Budbee.Web['body-lg'],
        gap: '16px',
        display: 'flex',
        padding: Spacing.lg,
        alignItems: 'center',
        marginTop: Spacing.lg,
        borderRadius: BorderRadius.md,
        border: `1.5px solid ${Color.AlertSecondary}`,
    },
    logoLink: {
        top: 0,
        left: '10%',
        display: 'block',
        position: 'absolute',
    },
    logoIcon: {
        display: 'block',
        width: '160px',
        height: 'auto',
        [MediaQuery.maxWidthMd]: {
            width: '108px',
        },
    },
    errorIcon: {
        width: IconSize['3xl'],
        height: IconSize['3xl'],
    },
    shake: {
        animationName: shake,
        animationDuration: '150ms',
        animationIterationCount: 2,
        animationTimingFunction: 'linear',
    },
});
