import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Favicon from 'react-favicon';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NotFound } from './components/not-found';
import { SearchParcel } from './components/search-parcel';
import BankIdRedirectRoute from './container/bankid-redirect-container';
import { BootContainer } from './container/boot-container';
import { store } from './store';
import { AdyenRedirect } from './utils/adyen-redirect';
import { getBrand } from './utils/brand/get-brand';
import initTranslations, { useLanguageHandler } from './utils/i18n';
import imageList from './utils/image-list';
initTranslations();
export const App = () => {
    return (_jsx(Provider, { store: store, children: _jsx(AppPages, {}) }));
};
const AppPages = () => {
    useLanguageHandler();
    const favicon = useFavicon();
    const brand = getBrand();
    return (_jsxs(BrowserRouter, { basename: "/", children: [_jsx(Favicon, { url: favicon }), _jsxs(Switch, { children: [brand === 'budbee' && _jsx(Route, { path: ['/', '/search'], exact: true, component: SearchParcel }), _jsx(Route, { path: "/:token", exact: true, component: BootContainer }), _jsx(Route, { path: "/adyen-redirect/:token", exact: true, component: AdyenRedirect }), _jsx(Route, { path: "/bankid-redirect/:token", exact: true, component: BankIdRedirectRoute }), _jsx(Route, { component: NotFound })] })] }));
};
function useFavicon() {
    const brand = getBrand();
    switch (brand) {
        case 'instabox':
            return imageList.instaboxFavicon;
        case 'porterbuddy':
            return imageList.porterbuddyFavicon;
        default:
            return imageList.budbeeFavicon;
    }
}
